import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GEHEADSALE, GET, GETUSERSALEALLNOLOT, POST } from "service";
import swal from "sweetalert2";
import ManagerCard from "./components/ManagerCard";

export class Manager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem("language"),
            date_start: new Date(),
            date_end: new Date(),
            header: [],
            data_tbl: [],
            data_header: [],
            search_text: ''
        };
    }
    componentDidMount = () => {
        this.getUserSale();
        this.getUserSaleHead();
    };

    getUserSaleHead = async () => {
        this.setState({ loading: true });
        try {
            let res = await GET(GEHEADSALE);
            if (res.success) {
                this.setState({ header: res.result })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getUserSale = async () => {
        const { date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        this.setState({ loading: true });
        try {
            let res = await POST(GETUSERSALEALLNOLOT, { date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                const { data_tbl, data_header } = res.result
                this.setState({ data_tbl, data_header })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitSearch = async () => {
        const { date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        this.setState({ loading: true });
        this.setState({ data_header: [] })
        try {
            // let res = await POST(GETUSERSALEALL, { date_start: start, date_end: end });
            let res = await POST(GETUSERSALEALLNOLOT, { date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                const { data_tbl, data_header } = res.result
                this.setState({ data_tbl, data_header })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    handleDateStartChange = async (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = async (date) => {
        this.setState({ date_end: date });
    };

    render() {
        const { loading, language, data_tbl, data_header, header } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <ManagerCard
                    language={language}
                    handleSearch={this.handleSearch}
                    submitSearch={this.submitSearch}
                    state={this.state}
                    handleDateStartChange={this.handleDateStartChange}
                    handleDateEndChange={this.handleDateEndChange}
                    data_tbl={data_tbl}
                    data_header={data_header}
                    header={header}
                />
            </Container>
        );
    }
}

export default withRouter(Manager);
