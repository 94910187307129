// const localhost = "http://localhost:4699";
const localhost = "https://admin.qrsfx.com";
// const path = localhost;
const path = window.location.origin.includes("localhost") ? localhost : "https://admin.qrsfx.com";
export const _ip = path;
export const ip = _ip + "/api/v1";

// export const ip_image = "http://localhost:4700/api/v1";
export const ip_image = "https://portal.qrsfx.com/api/v1";


export const GET = (path) => {
     return new Promise((resolve, reject) => {
          fetch(ip + path, {
               method: "GET",
               headers: {
                    "Content-Type": "application/json",
                    version: 1,
                    farmid: 0,
               },
               credentials: "include",
          })
               .then((response) => response.json())
               .then((json) => resolve(json))
               .catch((err) => reject(err));
     });
};

export const POST = (path, obj, formData) => {
     let lang = "th";
     return new Promise((resolve, reject) => {
          fetch(ip + path, {
               method: "POST",
               headers: formData
                    ? {}
                    : {
                         "Content-Type": "application/json",
                    },
               body: formData ? obj : JSON.stringify({ ...obj, lang }),
               credentials: "include",
          })
               .then((response) => response.json())
               .then((json) => resolve(json))
               .catch((err) => reject(err));
     });
};

export const POSTIMAGE = (path, obj, formData, token) => {
     let lang = "th";
     return new Promise((resolve, reject) => {
          fetch(ip_image + path, {
               method: "POST",
               headers: formData
                    ?
                    {
                         authorization: token,
                    }
                    : {
                         "Content-Type": "application/json",
                    },
               body: formData ? obj : JSON.stringify({ ...obj, lang }),
               credentials: "include",
          })
               .then((response) => response.json())
               .then((json) => resolve(json))
               .catch((err) => reject(err));
     });
};

export const GETIMAGE = (path, obj, formData, token) => {
     let lang = "th";
     return new Promise((resolve, reject) => {
          fetch(ip_image + path, {
               method: "POST",
               headers: formData
                    ?
                    {
                         authorization: token,
                    }
                    : {
                         "Content-Type": "application/json",
                    },
               body: formData ? obj : JSON.stringify({ ...obj, lang }),
               credentials: "include",
          })
               .then((response) => response)
               .then((json) => resolve(json))
               .catch((err) => reject(err));
     });
};

export const GETFILE = (filePath, filename) => {
     return new Promise((resolve, reject) => {
          fetch(ip + filePath, {
               method: "GET",
               headers: {
                    "Content-Type": "application/json",
                    version: 1,
                    farmid: 0,
               },
               credentials: "include",
          })
               .then(response => {
                    if (!response.ok) {
                         throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.blob();
               })
               .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = filename + new Date().toLocaleDateString('en-GB');
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    resolve();
               })
               .catch(err => {
                    reject(err);
               });
     });
};

//AUTH
export const LOGIN = "/auth/login";
export const LOGOUT = "/auth/logout";
export const CHECKSESSION = "/auth/checksession";
export const GETADMIN = "/auth";
export const ADDADMIN = "/auth/register";
export const DELETEADMIN = "/auth/delete_admin";
export const BANADMIN = "/auth/ban_admin";
export const EDITADMIN = "/auth/edit_info";

//BANNER
export const GETBANNER = "/banner";
export const UPLOADBANNER = "/banner/upload_image";

//PROMOTION
export const PROMOTIONAPI = "/promotion";
export const ADDPORMOTIONAPI = "/promotion/add_promotion";
export const UPLOADIMAGEPORMOTIONAPI = "/promotion/upload_image";
export const UPLOADIMAGEPORMOTIONENAPI = "/promotion/upload_image_en";
export const DELETEPORMOTIONAPI = "/promotion/delete_promotion";
export const PROMOTIONBYIDAPI = "/promotion/by_id";
export const EDITPROMOTION = "/promotion/edit_promotion";

//MARKETING
export const MARKETAPI = "/forex";
export const ADDMARKETAPI = "/forex/add_forex";
export const UPLOADIMAGEMARKETAPI = "/forex/upload_image";
export const DELETEMARKET = "/forex/delete_forex";
export const MATKETBYIDAPI = "/forex/by_id";
export const EDITMARKETAPI = "/forex/edit_forex";

//SERVICE
export const SERVICEAPI = "/service";
export const SERVICEBYIDAPI = "/service/by_id";
export const ADDSERVICEAPI = "/service/add_service";
export const EDITSERVICEAPI = "/service/edit_service";
export const UPLOADSERVICEIMAGEAPI = "/service/upload_image";
export const GETWITHDRAWAUTO = "/service/auto";
export const CONFIGWITHDRAWAUTO = "/service/config_auto";

//ACCOUNT_TYPE
export const ACCOUNTTYPEAPI = "/account_type";
export const ADDACCOUNTTYPEAPI = "/account_type/add_account_type";
export const DELETEACCOUNTTYPEAPI = "/account_type/delete_account_type";
export const ACCOUNTTYPEBYIDAPI = "/account_type/by_id";
export const ACCOUNTTYPEEDITAPI = "/account_type/edit_account_type";
export const ACCOUNTTYPEUPDATESTATUSAPI = "/account_type/update_status_account_type";

//QUESTION
export const FAQAPI = "/faq";
export const ADDFAQTYPE = "/faq/add_faq_type";
export const ADDFAQ = "/faq/add_faq";
export const EDITFAQTYPE = "/faq/edit_faq_type";
export const EDITFAQ = "/faq/edit_faq";
export const FAQBYID = "/faq/faq_by_id";
export const DELETEFAQ = "/faq/delete_faq";
export const DELETEFAQTYPE = "/faq/delete_faq_type";

//NEWS
export const NEWSAPI = "/news";
export const ADDNEWSAPI = "/news/add_news";
export const UPLOADIMAGENEWS = "/news/upload_image";
export const NEWSADMINBYID = "/news/by_id_admin";
export const EDITNEWS = "/news/edit_news";
export const DELETENEWS = "/news/delete_news";

//POLICY
export const POLICYAPI = "/policy";
export const POLICYBYID = "/policy/policy_by_id";
export const ADDPOLICYTYPE = "/policy/add_policy_type";
export const EDITPOLICYTYPE = "/policy/edit_policy_type";
export const ADDPOLICY = "/policy/add_policy";
export const EDITPOLICY = "/policy/edit_policy";
export const DELETEPOLICYTYPE = "/policy/delete_policy_type";
export const DELETEPOLICY = "/policy/delete_policy";
export const UPLOADPDFPOLICY = "/policy/update_pdf";

//MEMBER
export const ALLMEMBER = "/profile";
export const ALLDATAMEMBER = "/profile";
export const PROFILEBYUSER = "/profile/by_user_id";
export const PROFILEINVITEBYUSER = "/profile/invite_by_user_id";
export const VERIFYDOC = "/profile/verify_doc";
export const TRANSACTION = "/profile/transaction";
export const APPROVEREJECTDEPOSIT = "/profile/approve_wallet";
export const APPROVEREJECTWITHDRAW = "/profile/approve_withdraw";
export const EDITPROFILE = "/profile/edit_profile";
export const EDITDOC = "/profile/update_document";
export const GETHEADERINFO = "/profile/dashboard";
export const GETMT4 = "/profile/mt4";
export const GETMT = "/profile/mt";
export const DELETEMT = "/profile/deleteMT";
export const GETBORKERTRANSACTION = "/profile/broker_transaction";
export const UPDATEREMARK = "/profile/update_remark";
export const CHANGNETWORK = "/ib/change_network";
export const ADDCREDIT = "/profile/add_credit";
export const UPDATEPROFILEDOB = "/profile/edit_profile_dob";
export const NETWORKNESTBYID = "/profile/network_nest_by_id";
export const UPDATEPROFILEENABLE = "/profile/edit_enable";

//IB
export const IB = "/ib";
export const IBBYID = "/ib/ib_by_id";
export const EDITMANAGERPERCENT = "/ib/edit_manager_percent";
export const EDITIBPERCENT = "/ib/edit_ib_percent";
export const GENMANGERURL = "/ib/generate_manager_url";
export const NETWORK = "/ib/network";
export const IBSYMBOL = "/ib/symbol";
export const IBTOMANAGER = "/ib/ib_to_manager";
export const REGISSUBIB = "/ib/regist_sub_ib";
export const CANCELSUBIB = "/ib/cancle_sub_ib";
export const GETIBACCOUNTTYPE = "/ib/ib_account_type";
export const EDITIBACCOUNTTYPE = "/ib/ib_create_account_type";
export const GETCOUNTNETWORK = '/ib/count_network'
export const GETCOUNTNETWORKALL = '/ib/count_network_all'
export const GETIBNETWORK = '/ib/get_ib_network'
export const GETIBHEADER = '/ib/get_ib_header'
export const GETIBALL = '/ib/get_ib_network_header'
export const GETIBCHILD = '/ib/get_ib_child'
export const GETIBLISTUSER = '/ib/get_list_user_ib'
export const TRANSFERIB = '/ib/ib_transfer'
export const IBCAMPAIGNREPORT = '/ib/get_ib_campaign_report'
export const GETIBNEST = '/ib/get_ib_network_nest'
export const GETIBSELECTNEST = '/ib/get_ib_select_nest'
export const GETALLIBNEST = '/ib/get_all_ib_network_nest'
export const GETREPORTIBNEST = '/ib/get_report_ib_network_nest'
export const GETPORT = "/ib/ib_port"
export const GETIBSEARCH = '/ib/get_ib_network_header_search'

//exchange
export const PAYMENT = "/payment";
export const EDITEXCHANGE = "/payment/edit_exchange";

//user
export const WALLETUSER = "/profile/wallet_user";
export const DEPOSITCONFIG = "/profile/deposit_config";
export const WITHDRAWCONFIG = "/profile/withdraw_config";
export const DEPOSITEDIT = "/profile/deposit_edit";
export const WITHDRAWEDIT = "/profile/withdraw_edit";
export const DELETEUSER = "/auth/delete_user";
export const NETWORKBYID = "/profile/network_by_id";
export const EDITACCOUNTCOUNT = "/profile/edit_account_count";
export const EDITCASHBACK = "/profile/edit_cashback";
export const UPLOADBANKBOOKIMAGE = "/profile/admin_upload";
export const GETCASHBACK = "/profile/get_cashback";
export const BANUSER = "/profile/ban_user";

//SUPPORT
export const GETSUPPORT = "/support";
export const GETPROBLEMBYID = "/support/problem_by_id";
export const CHANGESTATUS = "/support/update_status";

//Sale User
export const GETUSERSALE = "/ib/user";
export const GETDATAALLSALE = "/ib/user";
export const GETUSERSALEREPORT = "/ib/user_report";

//report
export const GETREPORTMT4 = "/ib/mt4_user";
export const GETREPORTMT4TRANSACTION = "/ib/mt4_transaction";

//manageuser
export const GETUSERMANAGE = "/profile/get_user_has_no_invite";
export const ASSINGUSERTOSALE = "/profile/add_user_to_sale";
export const CANCELUSERSALE = "/profile/cancel_user_sale";
export const WELCOMEBONUS = "/profile/welcom_bonus";

//manager
export const GETUSERSALEALL = "/profile/get_all_sale";
export const GETUSERBYSALE = "/profile/get_user_by_sale";
export const GEHEADSALE = "/profile/get_head_sale_all";
export const GETUSERSALEALLNOLOT = "/profile/get_all_sale_no_lot";

//getImage
export const GETIMAGEPROFILE = "/profile/image";

//Change Password
export const CHANGEPASSWORD = "/profile/change_password";
export const CHANGEPASSWORDADMIN = "/auth/change_password";

//Reset Password Customer
export const RESETPASSWORDCUSTOMER = "/auth/reset_password_customer";

//Sale List
export const GETINOUTSALEADMIN = "/profile/get_in_out_sale_by_admin";
export const GETUSERINSALEADMIN = "/profile/get_user_in_sale";
export const GETINOUTSALEUSER = "/profile/get_in_out_sale_by_sale";
export const GETUSERINSALESALE = "/profile/get_user_in_sale_by_sale";
export const GETTRANSACTIONBYSALE = "/profile/get_transaction_by_sale";

//REBATE
export const GETREBATE = "/ib/ib_by_id_rebate";
export const EDITREBATE = "/ib/edit_ib_rebate";
export const EDITREBATESUB = "/ib/edit_ib_rebate_sub";
export const GETIBREBATE = '/ib/ib_by_id_rebate_sub'
export const GETIBACCOUNTREBATE = '/ib/account_type_rebate'
export const EDITIBACCOUNTREBATE = '/ib/edit_account_type_rebate'
export const GETSELECTCAMPAIGN = '/ib/get_select_campaign'
export const ADDCAMPAIGN = '/ib/add_campaign'
export const GETCAMPAIGN = '/ib/get_campaign'
export const DELETECAMPAIGN = '/ib/delete_campaign'
export const GETDATAEDITCAMPAIGN = '/ib/get_data_edit_campaign'
export const GETIBACCOUNTCAMPAIGN = '/ib/get_account_type_campaign'

//MT4 TRANSACTION
export const MT4TRANSACTION = "/profile/mt4_transacton";
export const GETTRANSACTIONMT4 = "/profile/get_transaction_mt4";
export const SEARCHTRANSACTIONMT4 = "/profile/search_transaction_mt4";
export const SEARCHTRANSACTIONMT4TEXT = "/profile/search_text_transaction_mt4";
export const UPDATEACCOUNTDEMO = "/profile/updateAccountDemo";

//Company
export const WITHDRAWCOMPANY = '/profile/withdraw_company'

//Cashback
export const GETALLCASHBACK = '/cashback/';
export const SEARCHCASHBACK = '/cashback/search_cashback';

//IB Transaction
export const GETIBTRANSACTION = "/ib/get_ib_transaction";
export const GETIBTRANSACTIONSUMMARY = "/ib/get_ib_transaction_summary";
export const GETIBTRANSACTIONBYDATE = "/ib/get_ib_transaction_by_date";
export const SEARCHIBTRANSACTIONTEXT = "/ib/search_text_ib_transaction";

//MT5
export const GETMT5ALL = "/account_type/account_type_mt5";
export const GETMT5TRANSACTION = "/ib/mt5_transaction";
export const GETACCOUNTTYPEALL = "/account_type/all";

//SETTINGEMAIL
export const UPDATEEMAIL = "/email/create_template";
export const GETEMAIL = "/email/get_template";
export const GETTYPEEMAIL = "/email/get_type_email";
export const SENDMAIL = "/email/send_mail";

export const REPORTDOWNLOAD = "/report/rebate";







