import React from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";
import CsvDownload from "react-csv-downloader";

const CardFooter = (props) => {
     const { data, language, page, handleChangePage, handleSearch, state, submitSearch, menuItem, role, OnclickOpenRemark, setIdToState, OnclickOpenLimitAccountModal, OnclickOpenAccountDemoModal, OnclickOpenCashbackModal, data_csv, count_page, getAllMember, getAllMemberSale, handleChangeEnableWithdraw, OnclickOpenCreditModal, handleDeleteUser, handleBanUser } = props;
     const count = Math.ceil(count_page > 0 && count_page / 10);
     const setPage = page - 1;
     // const dataList = data.slice(setPage * 10, page * 10);

     const columns = [
          {
               id: 'user_id',
               displayName: "User Id"
          },
          {
               id: 'username',
               displayName: "Name"
          },
          {
               id: 'wallet',
               displayName: "Wallet"
          },
          {
               id: 'balance',
               displayName: "MT5 Balance"
          },
          {
               id: 'ib_wallet',
               displayName: "IB Wallet"
          },
          {
               id: 'phone',
               displayName: "Mobile"
          },
          {
               id: 'email',
               displayName: "Email"
          },
          {
               id: 'created_at',
               displayName: "Register Date"
          },
          {
               id: 'user_status',
               displayName: "Active"
          }
     ];

     const onClickOpenMagageUser = () => {
          props.history.push("/manage_user");
     };
     return (
          <Grid container spacing={5} justifyContent="center" alignItems="center">
               <Grid item xs={12}>
                    <Grid container xs={12} justifyContent={"flex-end"}>
                         {role == "admin" || role == "normal" ? (
                              <Button
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   sx={{ maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                   onClick={onClickOpenMagageUser}
                              >
                                   {language === "th" ? "จัดการลูกค้า" : "Manage User"}
                              </Button>
                         ) : null}
                    </Grid>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>New Member</Typography>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                         <Grid container sx={{ width: "50vw", mr: 2 }}>
                              <GridTextField
                                   data={textFiled.search_text_dashboard}
                                   state={state}
                                   handleChange={handleSearch}
                                   onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                             submitSearch();
                                        }
                                   }}
                                   menuItem={menuItem}
                              />
                         </Grid>
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                              onClick={submitSearch}
                         >
                              {language === "th" ? "ค้นหา" : "Search"}
                         </Button>
                    </Stack>
               </Grid>
               <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                    <CsvDownload
                         style={{ color: "#fff", textDecoration: "none" }}
                         filename={`Member_Report_` + new Date().toLocaleString()}
                         extension='.csv'
                         columns={columns}
                         // datas={data_csv}
                         datas={(role == "admin" || role == "normal") ? getAllMember : getAllMemberSale}
                    >
                         <Button
                              // onClick={() => getAllMember()}
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                         >
                              export data
                         </Button>
                    </CsvDownload>
               </Grid>
               <Grid item xs={12}>
                    <GridTable
                         head={role === 'admin' ? headerTable.newmember_admin : headerTable.newmember}
                         // body={dataList}
                         body={data}
                         count={count}
                         page={page}
                         handleChangePage={handleChangePage}
                         role={role}
                         language={language}
                         OnclickOpenRemark={OnclickOpenRemark}
                         setIdToState={setIdToState}
                         OnclickOpenLimitAccountModal={OnclickOpenLimitAccountModal}
                         OnclickOpenAccountDemoModal={OnclickOpenAccountDemoModal}
                         OnclickOpenCashbackModal={OnclickOpenCashbackModal}
                         handleChangeEnableWithdraw={handleChangeEnableWithdraw}
                         OnclickOpenCreditModal={OnclickOpenCreditModal}
                         handleBanUser={handleBanUser}
                         handleDeleteUser={handleDeleteUser}
                    />
               </Grid>
          </Grid >
     );
};

export default withRouter(CardFooter);
