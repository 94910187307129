import { Box, Button, Collapse, Grid, IconButton, List, ListItemButton, ListItemText, Pagination, Stack, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/styles";
import { tofieds } from "functions/Functions";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

const theme = createMuiTheme();
const override = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
};

const NestedListItem = ({ item, header, level, language, showAll }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setOpen(showAll);
    }, [showAll]);

    return (
        <List sx={{ width: '100%', bgcolor: colorStyle[Number(level % 13)].color, py: 0 }} component="nav" aria-labelledby="nested-list-subheader">
            <ListItemButton>
                <ListItemText
                    primary={
                        < React.Fragment >
                            <Grid container direction={"row"} alignItems="center" >
                                <Grid item xs={12} sm={4.5} md={3.5} >
                                    <Stack width="100%" direction="row" spacing={1} alignItems="center">
                                        <Typography
                                            noWrap
                                            sx={{ fontSize: 16, color: "#0d47a1", fontWeight: "bold" }}
                                        >
                                            {item.email}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={4.5} md={3.5}>
                                    <Stack width="100%" direction="row" spacing={1} alignItems="center" sx={{ pl: level * 2 }}>
                                        <Typography
                                            noWrap
                                            sx={{ fontSize: 16, color: "#0d47a1", fontWeight: "bold" }}
                                        >
                                            ({Number(item.ib_status) === 1 ? "IB" : ""}{level + 1}) {item.name} {item.lastname}
                                        </Typography>
                                        {item.children && item.children.length > 0 && (
                                            <IconButton size="small" onClick={handleClick}>
                                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={3} md={5} display="flex" justifyContent="left">
                                    <Grid item container direction={"row"} alignItems="center" >
                                        {header.map((field, index) => (
                                            <Grid key={index} item xs={6} sm={6} md={6} display="flex" justifyContent="center" my={0.5}>
                                                <Stack width="100%" alignItems="center">
                                                    <Typography noWrap sx={{ fontSize: 12, color: "#0d47a1" }}>
                                                        {field.label}
                                                    </Typography>
                                                    {item[field.value] !== undefined ?
                                                        <Typography noWrap sx={{ fontSize: 16, color: "#0d47a1" }}>
                                                            {tofieds(item[field.value]) || "0.00"}
                                                        </Typography>
                                                        :
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <BeatLoader
                                                                color="#0d47a1"
                                                                loading={item[field.value] !== undefined ? false : true}
                                                                cssOverride={override}
                                                                size={5}
                                                                aria-label="Loading Spinner"
                                                                data-testid="loader"
                                                            />
                                                        </Box>
                                                    }
                                                </Stack>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                />
            </ListItemButton>
            {
                item.children && item.children.length > 0 && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {item.children.map((childItem, index) => (
                                <NestedListItem key={index} item={childItem} header={header} level={level + 1} language={language} showAll={showAll} />
                            ))}
                        </List>
                    </Collapse>
                )
            }
        </List >
    );
};

const TableNetworkNest = (props) => {
    const classes = useStyles();

    const [showAll, setShowAll] = useState(false);

    const handleShowAll = () => {
        setShowAll(!showAll)
    }

    return (
        <Grid container className="table-btn">
            <Grid item xs={12}>
                <Stack direction="row" width="100%" display="flex" alignItems="center" spacing={3}>
                    <Typography my={1} className={classes.fontStyleTitle}>Network</Typography>
                    <IconButton
                        onClick={() => {
                            handleShowAll();
                        }}
                    >
                        {showAll ? <CancelPresentationIcon fontSize="large" /> : <SettingsOverscanIcon fontSize="large" />}
                    </IconButton>
                </Stack>
            </Grid>
            <Grid item xs={12} direction={"row"} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px">
                <Grid item xs={12}>
                    {props.body.map((item, index) => (
                        <NestedListItem key={index} item={item} header={props.header} level={0} language={props.language} showAll={showAll} />
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#0d47a1" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
    fontStyleTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: "#000",
    },
}));

const colorStyle = {
    0: { color: "#FFFFFF" },
    1: { color: "#F8F8F8" },
    2: { color: "#F5F5F5" },
    3: { color: "#F0F0F0" },
    4: { color: "#E8E8E8" },
    5: { color: "#E0E0E0" },
    6: { color: "#DCDCDC" },
    7: { color: "#D8D8D8" },
    8: { color: "#D3D3D3" },
    9: { color: "#D0D0D0" },
    10: { color: "#C8C8C8" },
    11: { color: "#C0C0C0" },
    12: { color: "#BEBEBE" },
};

export default TableNetworkNest;
