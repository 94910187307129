import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import ListBList from "./IBRebateList/ListIBRebateList";
import { GET, IB, GENMANGERURL, POST, IBCAMPAIGNREPORT, REPORTDOWNLOAD, GETFILE } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

export class IBRebateList extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               data_ib: [],
               page: 1,
               search_text: "",
               generate: false,
               url: "http://localhost:3000/iblist",
               loading: false,
               count_page: 0,
               data_csv: [],
               download_success: false,
          };
     }
     componentDidMount = () => {
          this.getIB();
     };

     getIB = async (value) => {
          const { page, search_text } = this.state;
          try {
               this.setState({ loading: true });
               let ib = await POST(IB, { page: value ? value : page, text_search: search_text });
               if (ib.success) {
                    this.setState({
                         data_ib: ib.result.ib_data,
                         data: ib.result.ib_data,
                         count_page: ib.result.ib_count,
                    });
                    this.setState({ loading: false });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: ib.message, icon: "warning",
                         showConfirmButton: true
                    });
                    this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
               this.setState({ loading: false });
          }
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
          this.getIB(value);
     };

     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     submitSearch = async () => {
          const { search_text } = this.state;
          try {
               this.setState({ loading: true });
               let ib = await POST(IB, { page: 1, text_search: search_text });
               if (ib.success) {
                    this.setState({
                         data_ib: ib.result.ib_data,
                         data: ib.result.ib_data,
                         count_page: ib.result.ib_count,
                         page: 1
                    });
                    this.setState({ loading: false });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: ib.message, icon: "warning",
                         showConfirmButton: true
                    });
                    this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
               this.setState({ loading: false });
          }
     };

     onClickView = (id) => {
          this.props.history.push(`/ibrebatedetail/${id}`);
     };

     onClickCampaign = (id) => {
          this.props.history.push(`/ibrebatecampaign/${id}`);
     };

     onClickGenerate = async () => {
          try {
               this.setState({ loading: true });
               let genUrl = await GET(GENMANGERURL);
               if (genUrl.success) {
                    // let url = "https://portal.ats-forex.com/register/sale/" + genUrl.result.url;
                    let url = "https://portal.qrsfx.com/register/sale/" + genUrl.result.url;
                    this.setState({ url, generate: true, loading: false });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               this.setState({ loading: false });
          }
     };

     onClickCopy = async () => {
          if ("clipboard" in navigator) {
               swal.fire({
                    title: "Coppy สำเร็จ", icon: "success",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               return await navigator.clipboard.writeText(this.state.url);
          } else {
               return document.execCommand("copy", true, this.state.url);
          }
     };

     getIBCampaign = async () => {
          try {
               let res = await GET(IBCAMPAIGNREPORT);
               if (res.success) {
                    this.setState({ download_success: true })
                    return Promise.resolve
                         (Promise.all(res.result.map((el) => {
                              return {
                                   user_id: el.user_id,
                                   username: `${el.name} ${el.lastname}`,
                                   email: el.email,
                                   people: el.people,
                                   admin_name: el.admin_name,
                                   number_campaign: el.number_campaign
                              }
                         })))
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }
     };

     downloadReport = async () => {
          try {
               await GETFILE(REPORTDOWNLOAD, `IB_Rebate_Campaign_`);
               this.setState({ download_success: true })
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }
     };

     handleCloseAlert = (event, reason) => {
          if (reason === 'clickaway') {
               return;
          }
          this.setState({ download_success: false })
     };

     render() {
          const language = localStorage.getItem("language");
          const { page, data, generate, url, loading, count_page, download_success } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Loading open={loading} />
                    <Grid>
                         <ListBList
                              language={language}
                              data={data}
                              page={page}
                              handleChangePage={this.handleChangePage}
                              handleSearch={this.handleSearch}
                              state={this.state}
                              submitSearch={this.submitSearch}
                              onClickView={this.onClickView}
                              onClickGenerate={this.onClickGenerate}
                              onClickCopy={this.onClickCopy}
                              generate={generate}
                              url={url}
                              count_page={count_page}
                              onClickCampaign={this.onClickCampaign}
                              getIBCampaign={this.getIBCampaign}
                              downloadReport={this.downloadReport}
                              download_success={download_success}
                              handleCloseAlert={this.handleCloseAlert}
                         />
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(IBRebateList);
