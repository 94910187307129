import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETIBALL, GETIBNEST, GETIBHEADER, GETIBNETWORK, GETIBSELECTNEST, POST, GETALLIBNEST, GETREPORTIBNEST } from "service";
import swal from "sweetalert2";
import TableIBNest from "./TableIB/TableIBNest";

class IBDetailNest extends Component {
     constructor(props) {
          super(props);
          this.state = {
               page: 1,
               IB: [],
               dividend_text: [
                    {
                         xs: 12,
                         classNameGrid: "pt-0",
                         classNameText: "textfield-css mt-2",
                         placeholder: "Dividend",
                         name: "dividend",
                    },
               ],
               dividend: 0,
               tap_value: 2,
               edit_disabled_manager: true,
               edit_disabled_ib: true,
               name: "",
               dataSub: [],
               network: [],
               loading: false,
               symbol: [],
               checkForex: false,
               checkGold: false,
               checkCrypto: false,
               checkIndex: false,
               checkOil: false,
               rebate: [],
               manager_id: null,
               dataTabel: [],
               accountType: null,
               nav_sub_ib: [],
               count_network: [],
               date_start: new Date(),
               date_end: new Date(),
               data_header: [],
               data_header_profile: [],
               user_id: this.props.match.params.id,
               count_page: 0,
               data_ib_nest: [],
               download_success: false,
               showAll: false
          };
     }

     componentDidMount = () => {
          this.getNetwork();
          this.getHeader();
          this.getIBNest();
     };

     getAllIBNest = async () => {
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          try {
               let res = await POST(GETALLIBNEST, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
               if (res.success) {
                    this.setState({ data_ib_nest: res.result })
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }
     }

     getIBNest = async () => {
          this.setState({ data_ib_nest: [] })
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          try {
               this.setState({ loading: true });
               let res = await POST(GETIBNEST, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
               if (res.success) {
                    // console.log(res.result);
                    this.setState({ data_ib_nest: res.result })
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }
     }

     getNetwork = async () => {
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          try {
               this.setState({ loading: true });
               let network = await POST(GETIBNETWORK, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
               if (network.success) {
                    this.setState({ data_header_profile: network.result.filter((e) => Number(e.user_id) === Number(this.props.match.params.id)) })
                    if (network.result.length > 0) {
                         this.setState({ IB: network.result });
                    } else {
                         this.setState({ network: network.result });
                    }
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: network.message, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }
     };

     getHeader = async () => {
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          try {
               let amount = await POST(GETIBHEADER, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
               if (amount.success) {
                    this.setState({ data_header: amount.result })
               }
          } catch (error) {
               console.log(error)
          }
     }

     handleDataNest = async (user_id) => {
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          try {
               let res = await POST(GETIBSELECTNEST, { date_start: date_start_format, date_end: date_end_format, user_id });
               if (res.success) {
                    this.updateChild(user_id, res.result);
               }
          } catch (error) {
               console.log(error)
          }
     }

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     submitSearch = async () => {
          this.setState({ data_header: [], showAll: false })
          let { date_start, date_end, user_id } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          this.getIBNest();
          try {
               this.setState({ loading: true, page: 1 });
               let getIB = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id: user_id ? user_id : this.props.match.params.id, page: 1 });
               if (getIB.success) {
                    this.getHeader();
                    this.setState({ network: getIB.result.ib_data, IB: getIB.result.ib_data, count_page: getIB.result.count });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
          }
     };

     getReportIBNest = async () => {
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          try {
               this.setState({ loading: true });
               let res = await POST(GETREPORTIBNEST, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
               if (res.success) {
                    // console.log(res.result);
                    this.setState({ download_success: true })
                    this.setState({ loading: false });
                    return Promise.resolve
                         (
                              Promise.all(res.result.map((el, i) => {
                                   return {
                                        level: el.level,
                                        ib_status: Number(el.ib_status) === 1 ? "YES" : "NO",
                                        username: `${el.name} ${el.lastname}`,
                                        email: el.email,
                                        my_lot: el.my_lot,
                                        commission: el.commission,
                                        deposit: el.deposit,
                                        withdraw: el.withdraw0,
                                        net_deposit: el.net_deposit,
                                        net_deposit_team: el.net_deposit_team,
                                        team_deposit: el.team_deposit,
                                        team_withdraw: el.team_withdraw,
                                        team_lot: el.team_lot,
                                   }
                              }))
                         )
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(error)
          }
     }

     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };

     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     updateChild = (userId, newChildData) => {
          const { data_ib_nest } = this.state;
          const updateUser = (users) => {
               return users.map(user => {
                    if (user.user_id === userId) {
                         return { ...user, ...newChildData };
                    }

                    if (user.children.length > 0) {
                         return { ...user, children: updateUser(user.children) };
                    }

                    return user;
               });
          };

          this.setState({ data_ib_nest: updateUser(data_ib_nest) })
     };

     handleCloseAlert = (event, reason) => {
          if (reason === 'clickaway') {
               return;
          }
          this.setState({ download_success: false })
     };

     handleShowAll = () => {
          const { showAll } = this.state;
          this.setState({ showAll: !showAll })
     };

     render() {
          const { page, IB, dividend_text, tap_value, edit_disabled_manager, edit_disabled_ib, name, dataSub, loading, symbol, ib_accountype, nav_sub_ib, count_network, data_header, data_header_profile, count_page, data_ib_nest, download_success, showAll } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Loading open={loading} />
                    <TableIBNest
                         name={name}
                         // data_network={network}
                         data_network={data_ib_nest}
                         IB={IB}
                         data_header={data_header}
                         data_header_profile={data_header_profile}
                         nav_sub_ib={nav_sub_ib}
                         count_network={count_network}
                         dataSub={dataSub}
                         state={this.state}
                         dividend_text={dividend_text}
                         handleChangeText={this.handleChangeText}
                         tap_value={tap_value}
                         setTap={(e) => this.setState({ tap_value: e })}
                         edit_disabled_manager={edit_disabled_manager}
                         edit_disabled_ib={edit_disabled_ib}
                         symbol={symbol}
                         ib_accountype={ib_accountype}
                         userId={this.props.match.params.id}
                         handleDateStartChange={this.handleDateStartChange}
                         handleDateEndChange={this.handleDateEndChange}
                         submitSearch={this.submitSearch}
                         page={page}
                         handleChangePage={this.handleChangePage}
                         count_page={count_page}
                         handleDataNest={this.handleDataNest}
                         getAllIBNest={this.getAllIBNest}
                         getIBNest={this.getIBNest}
                         getReportIBNest={this.getReportIBNest}
                         download_success={download_success}
                         handleCloseAlert={this.handleCloseAlert}
                         handleShowAll={this.handleShowAll}
                         showAll={showAll}
                    />
               </Container>
          );
     }
}

export default withRouter(IBDetailNest);
