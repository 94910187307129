import { Grid, Stack, Switch, Typography } from "@material-ui/core";

const BonusSettingCard = (props) => {
    const {
        state,
        language,
        handleChangeEnableBonus,
    } = props;
    return (
        <Grid container columns={12} justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {language === "th" ? "ตั้งค่าโบนัส" : "Bonus Setting"}
                </Typography>
                <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000" }} my={3}>
                    {state.username} ({state.email})
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" spacing={5}>
                    <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>
                        {language === "th" ? "โบนัส" : "Bonus"}
                    </Typography>
                    <Switch
                        checked={state.enable_bonus}
                        onChange={() => handleChangeEnableBonus(state.enable_bonus)}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default BonusSettingCard;
