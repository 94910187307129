import { Button, Grid, Paper, Stack, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GridTextField } from "components";
import { headerTable, textFiled } from "functions/Static";
import DatePicker from "react-date-picker";
import GridTableSaleList from "./GridTableSaleList";
import GridPaperIB from "./GridPaperIB";

const SaleCard = (props) => {
     const {
          handleSearch,
          submitSearch,
          state,
          language,
          handleDateStartChange,
          handleDateEndChange,
          sale,
          deposit,
          withdraw,
          adminList,
          sumWithDraw,
          sumDeposit,
          role,
          allLot,
          net_deposit,
          page,
          handleChangePage,
          data_head,
          data_head_search,
     } = props;

     const classes = useStyles();
     const count = Math.ceil(adminList.length > 0 && adminList.length / 10);
     const setPage = page - 1;
     const dataList = adminList.slice(setPage * 10, page * 10);
     return (
          <Grid container spacing={2} justifyContent="center" alignItems="center">
               {role === "admin" && (
                    <Grid item xs={12}>
                         <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Sale list</Typography>
                         <Stack direction="row" alignItems="center" mt={2}>
                              <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                              <Grid container sx={{ width: "40vw", mr: 2 }}>
                                   <GridTextField data={textFiled.search_text_sale_list} state={state} handleChange={handleSearch} menuItem={sale} />
                              </Grid>
                              <Button
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                   onClick={submitSearch}
                              >
                                   {language === "th" ? "ค้นหา" : "Search"}
                              </Button>
                         </Stack>
                    </Grid>
               )}
               {/* <Grid item container spacing={5}>
                    <Grid item xs={12} md={3}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   ยอดฝาก
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {deposit || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   ยอดถอน
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {withdraw || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   Net Deposit
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {net_deposit || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
               </Grid> */}
               <Grid item xs={12}>
                    <GridPaperIB data={data_head} header={headerTable.head_sale_list} />
               </Grid>
               <Grid item container spacing={2} display="flex" alignItems="flex-end">
                    <Grid item xs={12} sm={3}>
                         <Typography variant="body1">วันที่เริ่ม</Typography>
                         <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                         <Typography variant="body1">วันที่สิ้นสุด</Typography>
                         <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={8} sm={3}>
                         <GridTextField
                              data={textFiled.search_text}
                              state={state}
                              handleChange={handleSearch}
                              onKeyDown={(e) => {
                                   if (e.key === "Enter") {
                                        submitSearch();
                                   }
                              }}
                         />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                              onClick={submitSearch}
                         >
                              {language === "th" ? "ค้นหา" : "Search"}
                         </Button>
                    </Grid>
               </Grid>
               {/* <Grid item container spacing={5}>
                    <Grid item xs={12} md={4}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   ยอดฝาก
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {sumDeposit || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   ยอดถอน
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {sumWithDraw || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   Lot รวม
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {allLot || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
               </Grid> */}
               <Grid item xs={12}>
                    <GridPaperIB data={data_head_search} header={headerTable.head_sale_list_search} />
               </Grid>
               <Grid item xs={12}>
                    <GridTableSaleList
                         head={headerTable.salelist}
                         body={dataList}
                         count={count}
                         page={page}
                         handleChangePage={handleChangePage}
                    />
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles(() => ({
     paper: {
          padding: 20,
     },
}));
export default SaleCard;
