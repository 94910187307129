import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const GridTableManager = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const onClicView = (id, name, lastname) => {
        // history.push(`/memberdetail/${id}`);
        history.push({
            pathname: `/memberdetail/${id}`,
            state: { username: `${name} ${lastname}` },
        });
    };

    const onClicViewTran = (id, name, lastname) => {
        history.push({
            pathname: `/transactiondetail/${id}`,
            state: {
                username: `${name} ${lastname}`,
                date_start: props.date_start,
                date_end: props.date_end,
            },
        });
    };

    //-------------------------------------------------//
    return (
        <Grid className="table-btn">
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {props.head.map((el, i) => {
                                return (
                                    <TableCell key={i} align={el.align || "left"}>
                                        <Typography noWrap>{el.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.body.map((el, i) => {
                            return (
                                <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell >
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.name} {el.lastname}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {Number(el.sumDeposit).toFixed(4) || "0.0000"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {Number(el.sumWithdraw).toFixed(4) || "0.0000"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {Number(el.sumNetDeposit).toFixed(4) || "0.0000"}
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.allLot !== null ? Number(el.allLot).toFixed(4) : "N/A"}
                                        </Typography>
                                    </TableCell> */}
                                    <TableCell align="center">
                                        <Link
                                            component="button"
                                            sx={{ fontSize: 16, color: "#184B8E" }}
                                            onClick={() => {
                                                onClicViewTran(el.admin_id, el.name, el.lastname);
                                            }}
                                        >
                                            View
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Link
                                            component="button"
                                            sx={{ fontSize: 16, color: "#184B8E" }}
                                            onClick={() => {
                                                onClicView(el.admin_id, el.name, el.lastname);
                                            }}
                                        >
                                            View
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 320,
    },
    fontActive: {
        fontSize: 15,
        color: "#fff",
        padding: 8,
        borderRadius: 10,
    },
});

export default GridTableManager;
