import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DatePicker from "react-date-picker";
import GridTableManager from "./GridTableManager";
import { headerTable, textFiled } from "functions/Static";
import GridPaperIB from "./GridPaperIB";
import { GridTextField } from "components";

const override = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
};

const ManagerCard = (props) => {
    const {
        language,
        state,
        submitSearch,
        handleDateStartChange,
        handleDateEndChange,
        data_tbl,
        data_header,
        header,
        handleSearch,
    } = props;

    const classes = useStyles();
    const role = localStorage.getItem("role");

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <GridPaperIB data={header} header={headerTable.sale_header_main} />
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={4}>
                    <Typography variant="body1">วันที่เริ่ม</Typography>
                    <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body1">วันที่สิ้นสุด</Typography>
                    <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                </Grid>
                <Grid item xs={8} sm={3}>
                         <GridTextField
                              data={textFiled.search_text}
                              state={state}
                              handleChange={handleSearch}
                              onKeyDown={(e) => {
                                   if (e.key === "Enter") {
                                        submitSearch();
                                   }
                              }}
                         />
                    </Grid>
                <Grid item xs={4} sm={1}>
                    <Button
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: "#4589C6" }}
                        sx={{ fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                        onClick={submitSearch}
                    >
                        {language === "th" ? "ค้นหา" : "Search"}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <GridPaperIB data={data_header} header={headerTable.sale_header} />
            </Grid>
            <Grid item xs={12}>
                <GridTableManager head={headerTable.managerList} body={data_tbl} date_start={state.date_start} date_end={state.date_end} />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    paper: {
        padding: 20,
    },
}));
export default ManagerCard;
